import React from "react";
import { ContentPage } from "../../components/ContentPage/ContentPage";

import "./AboutUs.scss";

export const AboutUs = () => {
  return (
    <div className="AboutUs">
      <ContentPage id={1747} />
    </div>
  );
};
