import React from "react";
import { ContentPage } from "../../components/ContentPage/ContentPage";

export const TermsOfUse = () => {
  return (
    <div className="TermsOfUse">
      <ContentPage id={258} />
    </div>
  );
};
